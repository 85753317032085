<template>
	<div>
		<Checkbox v-if="question.type == 'Checkbox'" :question="question" />
		<MultiChoice
			v-else-if="question.type == 'MultiChoice'"
			:question="question"
		/>
		<Instructions
			v-else-if="question.type == 'Instruction'"
			:question="question"
		/>
		<Text v-else-if="question.type == 'Text'" :question="question" />
		<DateTime
			v-else-if="question.type == 'DateTime'"
			:question="question"
		/>
		<Image v-else-if="question.type == 'Image'" :question="question" />
		<Equipment
			v-else-if="question.type == 'Equipment'"
			:question="question"
		/>
		<People v-else-if="question.type == 'People'" :question="question" />
		<Location
			v-else-if="question.type == 'Location'"
			:question="question"
		/>
		<Signature
			v-else-if="question.type == 'Signature'"
			:question="question"
		/>
	</div>
</template>

<script>
import { defineComponent } from "vue";
import Checkbox from "../questions/checkbox.vue";
import MultiChoice from "../questions/MultiChoice.vue";
import Instructions from "../questions/Instructions.vue";
import Text from "../questions/Text.vue";
import DateTime from "../questions/DateTime.vue";
import Image from "../questions/Image.vue";
import Equipment from "../questions/Equipment.vue";
import People from "../questions/People.vue";
import Location from "../questions/Location.vue";
import Signature from "../questions/Signature.vue";

export default defineComponent({
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
	components: {
		Checkbox,
		MultiChoice,
		Instructions,
		Text,
		DateTime,
		Image,
		Equipment,
		People,
		Location,
		Signature,
	},
	setup(props) {
		return {
			question: props.question,
		};
	},
});
</script>

<style lang="scss">
.question {
	display: flex;
	gap: 5px;

	* {
		width: 100%;
	}

	.question-text {
		padding-top: 4px;
		height: 100%;
		font-weight: 600;
	}

	.response {
		height: 100%;
		padding-left: 0.5em;
	}
}
</style>
