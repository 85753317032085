<template>
	<div>
		<div class="question">
			<div class="question-text">
				<p>{{ ques.question }}</p>
			</div>
		</div>
		<div class="question-image-grid">
			<div v-for="photo in ques.photos" :key="photo">
				<div class="image" v-if="!photo.startsWith('u')">
					<router-link
						:to="{
							name: 'ChecklistImageView',
							query: {
								photo: photo,
							},
						}"
					>
						<img crossorigin="*" v-bind:src="photo" />
					</router-link>
				</div>
				<div v-else>
					<img src="@/assets/image-placeholder.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const ques = props.question;

		return {
			ques,
		};
	},
});
</script>

<style lang="scss" scoped>
.question-image-grid {
	display: grid;
	margin-top: 5px;
	padding-top: 1em;
	grid-template-columns: repeat(5, 120px);
	grid-template-rows: auto;
	column-gap: 1rem;
	row-gap: 1rem;
	justify-items: center;
	align-items: center;
	border-top: 1px solid $rm-grey;

	.image {
		background-color: $rm-light-grey;
	}
}
</style>
