<template>
	<div class="question">
		<div class="question-text">
			<p>{{ ques.question }}</p>
		</div>
		<div class="response">
			<div class="res-text">
				<p>{{ ques.value }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const ques = props.question;

		return {
			ques,
		};
	},
});
</script>

<style lang="scss" scoped>
.res-text {
	background-color: white;
	padding: 0.5em;
	border: 1px solid $rm-grey;
}
</style>
