<template>
	<div class="question">
		<div class="question-text">
			<p>{{ ques.question }}</p>
		</div>
		<div class="response">
			<div class="res-text">
				<p>
					{{ ques.assignedDate }}
					<span
						v-if="
							ques.assignedDate != null &&
							ques.assignedTime != null
						"
						>-</span
					>
					<span v-if="ques.assignedTime != null">
						&nbsp;{{ ques.assignedTime.split(" ").join("") }}
					</span>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const ques = props.question;

		return {
			ques,
		};
	},
});
</script>
