<template>
	<div class="question">
		<div class="question-text">
			<p>{{ ques.question }}</p>
			<br />
			<p>{{ ques.value }}</p>

			<p style="font-size: 0.8em; font-weight: 400; margin-top: 0.5em">
				latitude:
				{{ ques.latitude }}, longitude:
				{{ ques.longitude }}
			</p>
		</div>
		<div class="response">
			<div class="res-map">
				<GoogleMap
					:api-key="mapAPIKey"
					style="width: 420px; height: 300px"
					mapTypeId="satellite"
					:center="{
						lat: ques.latitude,
						lng: ques.longitude,
					}"
					:zoom="17"
					:mapTypeControl="false"
				>
					<Marker
						:options="{
							position: {
								lat: ques.latitude,
								lng: ques.longitude,
							},
						}"
					/>
				</GoogleMap>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";
import SvcUtils from "../../../misc/env";

export default defineComponent({
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
	components: {
		GoogleMap,
		Marker,
	},
	setup(props) {
		const ques = props.question;
		const APIKey = ref("");
		APIKey.value = SvcUtils.GOOGLE_MAP_KEY;

		const mapAPIKey = computed(() => {
			return APIKey.value;
		});

		return {
			ques,
			mapAPIKey,
		};
	},
});
</script>

<style lang="scss" scoped>
.res-map {
	background-color: $rm-light-grey;
	.open-map {
		position: absolute;
		top: 10px;
		right: -135px;
		color: white;
	}
}
</style>
