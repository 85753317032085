<template>
	<div class="res-signatures">
		<div class>
			<div v-for="signature in ques.signatures" :key="signature.id">
				<div class="signature">
					<div class="sig-name">
						<div class="name">
							{{ signature.name }}
						</div>
						<span class="label">{{ ques.question }}</span>
					</div>

					<div class="sig-img">
						<div class="image">
							<img
								class="signature-img"
								crossorigin="*"
								v-bind:src="signature.serverImagePath"
								v-if="
									!signature.serverImagePath.startsWith('u')
								"
							/>
							<img
								v-else
								class="signature-img"
								src="@/assets/image-placeholder.png"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const ques = props.question;

		return {
			ques,
		};
	},
});
</script>

<style lang="scss" scoped>
.res-signatures {
	display: flex;
	flex-direction: column;
	gap: 1em;
	background-color: $rm-off-white;
	// border-bottom: 2px solid $rm-dark-grey;
	margin-top: 0.5em;

	.signature {
		display: flex;
		justify-content: space-between;
		align-items: center;
		// border: 1px solid $rm-grey;
		padding-bottom: 5px;

		.sig-name {
			height: max-content;
			width: 50%;
			margin: 1em;

			.name {
				font-size: 1.2em;
				padding: 0 0.5em;
				border-bottom: 1px solid;
			}

			.label {
				font-size: 0.8em;
				color: $rm-dark-grey;
				margin-left: 0.6em;
			}
		}

		.sig-img {
			position: relative;
			width: 30%;
			margin: 1em;
			padding: 1em;
			border: 1px dashed;
			background-color: white;

			p {
				position: absolute;
				left: 2px;
				bottom: 2px;
				font-size: 7px;
			}

			.image {
				display: flex;
				justify-content: center;

				img {
					width: 60%;
				}
			}
		}
	}
}
</style>
