<template>
	<div class="question">
		<div class="question-text">
			<p>{{ ques.question }}</p>
		</div>
		<div class="response">
			<div class="res-select">
				<div class="res-users">
					<div v-for="employee in employees" :key="employee.id">
						<div class="user">
							<div class="circle-avatar">
								<img :src="employee.profile.profileImage" />
							</div>
							<div class="text">
								{{ employee.profile.fullName }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { projectFirestore } from "../../../firebase/config";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const ques = props.question;
		const employees = ref([]);

		onMounted(async () => {
			await getEmployees();
		});

		const getEmployees = async () => {
			try {
				let employeePromises = [];

				for (let userId of ques.users) {
					employeePromises.push(getEmployee(userId));
				}

				const results = await Promise.all(employeePromises);
				employees.value.push(...results);
			} catch (error) {
				console.log("Error getting employees: ", error);
			}
		};

		const getEmployee = async (id) => {
			try {
				const userRef = projectFirestore.collection("users").doc(id);
				const userDoc = await userRef.get();
				const user = { id: userDoc.id, ...userDoc.data() };

				const employeeRef = projectFirestore
					.collection("companies")
					.doc(user.companyId)
					.collection("employees")
					.doc(user.id);
				const employeeDoc = await employeeRef.get();
				const employee = { id: employeeDoc.id, ...employeeDoc.data() };

				return employee;
			} catch (error) {
				console.log("Error getting employee: ", error);
			}
		};

		return {
			ques,
			employees,
		};
	},
});
</script>

<style lang="scss" scoped>
.res-users {
	display: flex;
	flex-direction: column;
	gap: 3px;

	.user {
		display: flex;
		align-items: center;
		gap: 1em;
		background-color: white;
		padding: 0.5em 1em;

		.text {
			min-width: 100%;
		}
	}
}
</style>
