<template>
	<div class="question">
		<div class="question-text">
			<p>{{ ques.question }}</p>
		</div>
		<div class="response">
			<div class="res-check">
				<div v-if="ques.value == '1'" class="check-icon check-selected">
					<fa icon="check" />
				</div>
				<div v-else class="check-icon">
					<fa icon="check" />
				</div>

				<div
					v-if="ques.value == '2'"
					class="check-icon check-selected-flag"
				>
					<fa icon="flag" />
				</div>
				<div v-else class="check-icon">
					<fa icon="flag" />
				</div>

				<div
					v-if="ques.value == '3'"
					class="check-icon check-selected-danger"
				>
					<fa icon="exclamation-triangle" />
				</div>
				<div v-else class="check-icon">
					<fa icon="exclamation-triangle" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const ques = props.question;

		return {
			ques,
		};
	},
});
</script>

<style lang="scss" scoped>
.res-check {
	display: flex;
	justify-content: flex-end;
	gap: 1em;

	.check-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		border: 2px solid;
		color: $rm-grey;
	}

	.check-selected {
		border-color: $rm-success;
		color: white !important;
		background-color: $rm-success;
	}

	.check-selected-flag {
		border-color: $rm-warning;
		color: white !important;
		background-color: $rm-warning;
	}

	.check-selected-danger {
		border-color: $rm-danger;
		color: white !important;
		background-color: $rm-danger;
	}
}
</style>
