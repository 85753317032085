<template>
	<div class="question">
		<div class="question-text">
			<p>{{ ques.question }}</p>
		</div>

		<div class="response">
			<div class="res-equipment">
				<div
					v-for="equipment in ques.equipmentDetails"
					:key="equipment.identifier"
					class="item"
				>
					<div class="item-name">
						<p>
							{{ equipment.name }}
						</p>
						<router-link
							class="equipment-link"
							:to="{
								name: 'EquipmentDetail',
								params: {
									id: equipment.identifier,
								},
							}"
							target="_blank"
						>
							<fa icon="external-link-alt" />
						</router-link>
					</div>
					<div class="item-details">
						<div class="item-status">
							<fa
								v-if="equipment.checkedStatus == 'Safe for use'"
								class="check-circle"
								icon="check-circle"
							/>
							<fa
								v-if="
									equipment.checkedStatus ==
									'Time-critical issue'
								"
								class="flag"
								icon="flag"
							/>
							<fa
								v-if="equipment.checkedStatus == 'Do not use'"
								class="exclamation-triangle"
								icon="exclamation-triangle"
							/>
							<fa
								v-if="equipment.checkedStatus == 'Missing'"
								class="question-circle"
								icon="search"
							/>
							<fa
								v-if="
									equipment.checkedStatus == 'Unchecked' ||
									equipment.checkedStatus == null ||
									equipment.checkedStatus == undefined
								"
								class="question-circle"
								icon="question-circle"
							/>
						</div>
						<div class="item-id">
							{{ equipment.identifier }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const ques = props.question;

		return {
			ques,
		};
	},
});
</script>

<style lang="scss" scoped>
.res-equipment {
	display: flex;
	flex-direction: column;
	gap: 3px;

	.item {
		padding: 0.5em 1em;
		background-color: white !important;

		.item-name {
			display: flex;
			width: 100%;
			justify-content: space-between;
			font-weight: bold;

			.equipment-link {
				width: fit-content;
			}
		}

		.item-details {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-size: 0.8em;
			gap: 0.5em;
			margin-top: 5px;

			.item-status {
				width: fit-content;
				color: $rm-grey;

				.check-circle {
					color: $rm-success;
				}

				.flag {
					color: $rm-warning;
				}

				.exclamation-triangle {
					color: $rm-danger;
				}
			}
		}
	}
}
</style>
