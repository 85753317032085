<template>
	<div
		class="instruction"
		id="instruction"
		v-html="ques.question"
		v-if="ques.question"
	></div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const ques = props.question;

		return {
			ques,
		};
	},
});
</script>

<style lang="scss" scoped>
.instruction {
	margin: 1em 0 0;
	padding: 1em;
	background-color: $rm-off-white;
	display: flex !important;
	flex-direction: column !important;
	gap: 1em !important;
}
</style>
