<template>
	<div class="question">
		<div class="question-text">
			<p>{{ ques.question }}</p>
		</div>
		<div class="response">
			<div class="res-select">
				<div v-for="choice in ques.choices" :key="choice.id">
					<div
						class="btn cl-btn res-selected"
						v-if="choice.value == true"
						:class="[choice.color ?? 'darkgrey']"
					>
						{{ choice.text }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const ques = props.question;

		return {
			ques,
		};
	},
});
</script>

<style lang="scss" scoped>
.res-select {
	display: flex;
	flex-direction: column;

	.cl-btn {
		background-color: transparent;
		border: 1px solid $rm-grey;
		color: $rm-grey;
		text-align: center;
		margin: 5px 0;
		border-radius: 0;
	}

	.res-selected {
		color: white;
	}

	.green {
		background-color: green;
	}

	.yellow {
		background-color: $rm-warning;
	}

	.darkorange {
		background-color: darkorange;
	}

	.red {
		background-color: $rm-danger;
	}

	.darkgrey {
		background-color: $rm-grey;
	}

	.cornflowerblue {
		background-color: cornflowerblue;
	}

	.white {
		background-color: white;
		color: $rm-dark-blue !important;
	}
}
</style>
